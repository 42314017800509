import { useEffect, useRef, useState } from 'react';

import BookIcon from '../../assets/images/navigation-icons/Sidebar/book.svg';
import ChatIcon from '../../assets/images/navigation-icons/Sidebar/chat.svg';
import ClockIcon from '../../assets/images/navigation-icons/Sidebar/clock.svg';
import CodeIcon from '../../assets/images/navigation-icons/Sidebar/code.svg';
import CollapseIcon from '../../assets/images/navigation-icons/Sidebar/collapse.svg';
import CodeLinesIcon from '../../assets/images/navigation-icons/Sidebar/code-lines.svg';
import DollarArrowsIcon from '../../assets/images/navigation-icons/Sidebar/dollar-arrows.svg';
import DollarIcon from '../../assets/images/navigation-icons/Sidebar/dollar.svg';
import ExpandIcon from '../../assets/images/navigation-icons/Sidebar/expand.svg';
import HandsIcon from '../../assets/images/navigation-icons/Sidebar/hands.svg';
import PlayCircleIcon from '../../assets/images/navigation-icons/Sidebar/play-circle.svg';
import RobotIcon from '../../assets/images/navigation-icons/Sidebar/robot.svg';
import TagIcon from '../../assets/images/navigation-icons/Sidebar/tag.svg';
import TimeIcon from '../../assets/images/navigation-icons/Sidebar/time.svg';
import UsersIcon from '../../assets/images/navigation-icons/Sidebar/users.svg';
import ClaimsIcon from '../../assets/images/navigation-icons/Sidebar/claims.svg';
import LogoYellow from '../../assets/images/logo-yellow.svg';
import LogoFullDark from '../../assets/images/logo-full-dark.svg';
import Badge from '../Badge/Badge';
import { useUser } from '../../userContext';
import { useHover, useWindowSize, useSessionStorage } from 'usehooks-ts';
import { RouteHelper } from '../../utils/RouteHelper';
import { HiShieldExclamation } from 'react-icons/hi';

const desktopWidth = 1440;

const environmentLabel = window?.CONFIG.env ?? 'production';

type SidebarNavItemProps = {
  open: boolean;
  children: React.ReactNode;
  title: string;
  badgeContent?: string;
} & React.ComponentProps<'a'>;

interface SidebarNavProps {
  onToggleOpen: (isOpen: boolean) => void;
  isNavBarOpen: boolean;
  myActionableInterviewCount: number;
}

function SidebarNavItem(props: SidebarNavItemProps) {
  const { className, open, href, title, badgeContent, ...aAttrs } = props;
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  const navItemClass = `px-2 h-12 flex items-center gap-2 rounded sm:my-0.5 ${
    open ? 'w-full ml-7 mr-7' : 'justify-center'
  }`;

  return (
    <a
      href={href}
      className={`${navItemClass} ${className} ${isHover ? 'bg-iioNavActive text-white' : ''}`}
      ref={hoverRef}
      {...aAttrs}
    >
      {props.children}
      {open && <span>{title}</span>}
      {badgeContent && (
        <div className={`${!open ? 'hidden sm:block absolute mb-6 ml-6' : ''}`}>
          <Badge className="" isNew alertType="white" icon="">
            {badgeContent}
          </Badge>
        </div>
      )}
      {!open && isHover && (
        <div
          className={`absolute min-w-max px-3 py-1 flex items-center bg-black left-4.5rem rounded text-base text-white font-semibold`}
        >
          <span>{title}</span>
        </div>
      )}
    </a>
  );
}

function SidebarNav(props: SidebarNavProps) {
  const { onToggleOpen, isNavBarOpen, myActionableInterviewCount } = props;
  const { width = 0 } = useWindowSize();
  const { user } = useUser();
  const [open, setOpen] = useSessionStorage<boolean | undefined>('nav-open-state', undefined);
  const [windowSizeEnabled, setWindowSizeEnabled] = useState<boolean>(false);

  const toggleOpen = () => {
    setOpen(!open);
    onToggleOpen(!open);
  };

  useEffect(() => {
    if (!windowSizeEnabled) {
      setWindowSizeEnabled(true);
      return;
    }
    if (width >= desktopWidth) {
      setOpen(true);
      onToggleOpen(true);
    } else {
      setOpen(false);
      onToggleOpen(false);
    }
  }, [width]);

  useEffect(() => {
    if (open === undefined) {
      setOpen(isNavBarOpen);
      onToggleOpen(isNavBarOpen);
    } else {
      onToggleOpen(open);
    }
  }, [isNavBarOpen, open]);

  const containerWidthClass = open ? 'sm:w-60 w-full' : 'sm:w-16 w-0';
  const backgroundColor =
    environmentLabel !== 'production' ? `bg-environment-${window?.CONFIG.env ?? 'production'}` : 'bg-black';
  const textColor = environmentLabel !== 'production' ? 'text-black' : 'text-iioNavText';
  const navItemActive = environmentLabel !== 'production' ? `sm:bg-black` : 'sm:bg-iioNavActive';

  return (
    <div
      className={`sm:px-2 fixed z-50 flex flex-col h-full ${backgroundColor} ${containerWidthClass} ${textColor} transition-width ease-in-out top-14 sm:top-0`}
      id="site-nav-container"
    >
      <div className="flex flex-col items-center text-sm mt-0 sm:pt-3 gap-2 sm:gap-0 sm:justify-end overflow-x-hidden overflow-y-auto h-full mb-14 sm:mb-0">
        <a href="/" className="ml-2 mb-3 self-start hidden sm:block">
          {open ? (
            <img height="32px" width="200px" className="-ml-1" src={LogoFullDark} alt="Interviewing.io home" />
          ) : (
            <img width="32px" height="32px" src={LogoYellow} alt="Interviewing.io home" />
          )}
        </a>
        {(user.roleAllowed === 'both' || user.roleAllowed === 'interviewee') && (
          <SidebarNavItem
            href="/dashboard/interviewee"
            className={`${RouteHelper.isIntervieweeDashboard(window.location) ? `${navItemActive} text-white` : ''}`}
            open={open}
            title="Get Interviewed"
          >
            <img className="h-6 w-6" src={CodeIcon} alt="Get Interviewed" />
          </SidebarNavItem>
        )}
        <SidebarNavItem
          open={open}
          title="Upcoming"
          href="/schedule/view-schedule"
          className={`${RouteHelper.isUpcoming(window.location) ? `${navItemActive} text-white` : ''}`}
        >
          <img className="h-6 w-6" src={ClockIcon} alt="Upcoming" />
        </SidebarNavItem>

        <SidebarNavItem
          title="History"
          open={open}
          href="/history"
          className={`${
            RouteHelper.isHistory(window.location) || RouteHelper.isFeedback(window.location)
              ? `${navItemActive} text-white`
              : ''
          }`}
          badgeContent={myActionableInterviewCount !== 0 ? myActionableInterviewCount.toString() : undefined}
        >
          <img className="h-6 w-6" src={TimeIcon} alt="History" />
        </SidebarNavItem>
        {user && !user.isRestricted && (
          <>
            <SidebarNavItem
              href="/dashboard/interviewee/dedicated-coaching/"
              className={`${RouteHelper.isDedicatedCoaching(window.location) ? `${navItemActive} text-white` : ''}`}
              open={open}
              title="Dedicated Coaching"
            >
              <img className="h-6 w-6" src={UsersIcon} alt="Dedicated Coaching" />
            </SidebarNavItem>
            <SidebarNavItem
              href="/salary-negotiation"
              open={open}
              title="Salary Negotiation"
              className={`${RouteHelper.isSalaryNegotiation(window.location) ? `${navItemActive} text-white` : ''}`}
            >
              <img className="h-6 w-6" src={DollarArrowsIcon} alt="Salary Negotiation" />
            </SidebarNavItem>
          </>
        )}
        <SidebarNavItem
          title="The Book"
          open={open}
          ng-if="viewBookFeatureFlag"
          href="/beyond-ctci/part-i-preface/what-is-this-book"
          className={`${RouteHelper.isViewBook(window.location) ? `${navItemActive} text-white` : ''}`}
          badgeContent="NEW"
        >
          <img className="h-6 w-6" src={BookIcon} alt="Beyond Cracking the Coding Interview Book" />
        </SidebarNavItem>
        <SidebarNavItem
          open={open}
          title="AI Interviewer"
          href="/interview-ai"
          className={`${RouteHelper.isAiInterviewer(window.location) ? `${navItemActive} text-white` : ''}`}
        >
          <img className="h-6 w-6" src={RobotIcon} alt="AI Interviewer" />
        </SidebarNavItem>
        {user.canHaveJobsAccess && (
          <SidebarNavItem
            open={open}
            title="Company Intros"
            href="/company-introductions"
            className={`${RouteHelper.isCompanyIntroductions(window.location) ? `${navItemActive} text-white` : ''}`}
          >
            <img className="h-6 w-6" src={HandsIcon} alt="Company Introductions" />
          </SidebarNavItem>
        )}
        {!user.isRestricted && (
          <SidebarNavItem
            className={`${RouteHelper.isShowcase(window.location) ? `${navItemActive} text-white` : ''}`}
            open={open}
            title="Showcase"
            href="/showcase"
          >
            <img className="h-6 w-6" src={PlayCircleIcon} alt="Showcase" />
          </SidebarNavItem>
        )}
        <SidebarNavItem
          className={`${RouteHelper.isSandbox(window.location) ? `${navItemActive} text-white` : ''}`}
          open={open}
          title="Sandbox"
          href="/interview/playground"
          data-title="Sandbox"
        >
          <img className="h-6 w-6" src={CodeLinesIcon} alt="Sandbox" />
        </SidebarNavItem>
        {user._canRefer && (
          <SidebarNavItem
            open={open}
            title="Referrals"
            href="/referrals"
            className={`${RouteHelper.isReferrals(window.location) ? `${navItemActive} text-white` : ''}`}
          >
            <img className="h-6 w-6" src={DollarIcon} alt="Referrals" />
            {/* <counter ng-if="referralsPageBadgeCount" count="referralsPageBadgeCount" data-alert="true"></counter> */}
          </SidebarNavItem>
        )}
        <SidebarNavItem
          open={open}
          title="Company Process Guides"
          href={`${window.CONFIG.marketingUrl}/topics#companies`}
          target="_blank"
        >
          <img className="h-6 w-6" src={TagIcon} alt="Company Process Guides" />
        </SidebarNavItem>
        {(user.roleAllowed === 'both' || user.roleAllowed === 'interviewer') && (
          <SidebarNavItem
            open={open}
            title="Interview Others"
            href="/dashboard/interviewer"
            className={`${
              RouteHelper.isSetAvailability(window.location) || RouteHelper.isInterviewerDashboard(window.location)
                ? `${navItemActive} text-white`
                : ''
            }`}
          >
            <img className="h-6 w-6" src={ChatIcon} alt="Interview Others" />
          </SidebarNavItem>
        )}
        {(user.roleAllowed === 'both' || user.roleAllowed === 'interviewer') && user._paidInterviewer === 'yes' && (
          <SidebarNavItem
            open={open}
            title="Claims"
            href="/claim"
            className={`${RouteHelper.isClaim(window.location) ? `${navItemActive} text-white` : ''}`}
          >
            <img className="h-6 w-6" src={ClaimsIcon} alt="Claims" />
          </SidebarNavItem>
        )}
        {user.role === 'admin' && (
          <SidebarNavItem
            open={open}
            title="Super Admin"
            href="/admin"
            className={`$text-red-500 ${
              RouteHelper.isSuperAdmin(window.location) ? `${navItemActive} text-white` : ''
            }`}
          >
            <HiShieldExclamation
              className={`h-6 w-6 ${!open && 'hidden sm:block'} text-red-500`}
              aria-label="Super admin"
            />
          </SidebarNavItem>
        )}

        <button className={`h-[30px] mb-2 mt-auto hidden sm:block ${open ? 'ml-auto mr-2' : ''}`} onClick={toggleOpen}>
          {open ? <img src={CollapseIcon} className="h-6 w-6" /> : <img src={ExpandIcon} className="h-6 w-6" />}
        </button>
      </div>
    </div>
  );
}

export default SidebarNav;
